import Vue from 'vue';

// axios
import axios from 'axios';
import router from '@/router/index.js';
import i18n from '@/libs/i18n';
import store from '@/store/index.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const authorizationToken = localStorage.Authorization ? `Bearer ${localStorage.Authorization}` : '';

export const baseURL = 'https://b2bapi.wtw.network/api';

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL || baseURL,
  // timeout: 50,
  headers: {
    Accept: 'application/json',
    Authorization: authorizationToken,
    'Accept-Language': i18n.locale,
  },
});

axiosIns.interceptors.response.use(
  response => {
    if (response.data.status === false) {
      throw response.data.error;
    }
    return response;
  },
  async error => {
    if (error.response.status === 422) {
      const errText = Object.values(error.response.data.errors).flatMap(err => err).join('<br>');

      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: errText,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    }

    const statusesToInclude = [403, 404];
    if (statusesToInclude.includes(error.response.status)) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: error.response.statusText,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    }

    if (error.response.status === 401 && router.currentRoute?.meta?.requiresAuth) {
      await store.dispatch('profile/logout', false);
      await router.push({ name: 'auth-login' });
    }

    throw error;
  },
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
