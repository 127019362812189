export const RUB_ID = 0;
export const USD_ID = 1;
export const BYN_ID = 2;

export const currencyOptions = [
  {
    value: RUB_ID,
    label: 'RUB',
  },
  {
    value: USD_ID,
    label: 'USD',
  },
  {
    value: BYN_ID,
    label: 'BYN',
  },
];

export const SYMBOLS = {
  [RUB_ID]: '₽',
  [USD_ID]: '$',
  [BYN_ID]: 'BYN',
};

export const getCurrencySymbol = currency => SYMBOLS[currency] ?? 'unknown';
