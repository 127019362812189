import i18n from '@/libs/i18n';

export default [
  {
    path: '/trading/requests-list/:param',
    name: 'trading-requests-list',
    meta: {
      pageTitle: i18n.t('requests'),
      requiresAuth: true,
      contentClass: 'ecommerce-application',
    },
    component: () => import('@/views/trading/trading-requests/TradingRequests.vue'),
  },
  {
    path: '/trading/requests/:id',
    name: 'trading-requests-add',
    meta: {
      pageTitle({ params: { id } }) {
        return id === 'new' ? i18n.t('addRequestTitle') : i18n.t('editRequest', { requestId: id });
      },
      requiresAuth: true,
    },
    component: () => import('@/views/trading/trading-requests/TradingRequestsNew.vue'),
  },
  {
    path: '/trading/requests/preview/:id',
    name: 'trading-requests-preview',
    meta: {
      pageTitle({ params: { id } }) {
        return i18n.t('previewRequest', { requestId: id });
      },
      contentClass: 'ecommerce-application',
      requiresAuth: true,
    },
    component: () => import('@/views/trading/trading-requests/TradingRequestPreview.vue'),
  },
  {
    path: '/trading/offers/list',
    name: 'trading-offers-list',
    meta: {
      pageTitle: i18n.t('offers'),
      requiresAuth: true,
    },
    component: () => import('@/views/trading/trading-offers/TradingOffers.vue'),
  },
  {
    path: '/trading/open-offers/:param',
    name: 'trading-open-offers-list',
    meta: {
      pageTitle: i18n.t('openOffers'),
      requiresAuth: true,
      contentClass: 'ecommerce-application',
      contentRenderer: 'sidebar-left-detached',
    },
    component: () => import('@/views/trading/trading-offers/TradingOpenOffers.vue'),
    props: route => ({ param: route.params.param }),
  },
  {
    path: '/trading/offers/:id',
    name: 'trading-offers-add',
    meta: {
      pageTitle({ params: { id } }) {
        return id === 'new' ? i18n.t('addOfferTitle') : i18n.t('editOffer', { offerId: id });
      },
      requiresAuth: true,
    },
    component: () => import('@/views/trading/trading-offers/TradingOffersNew.vue'),
  },
  {
    path: '/trading/offers/preview/:id',
    name: 'trading-offers-preview',
    meta: {
      pageTitle({ params: { id } }) {
        return i18n.t('previewOffer', { offerId: id });
      },
      requiresAuth: true,
    },
    component: () => import('@/views/trading/trading-offers/TradingOfferPreview.vue'),
  },
  {
    path: '/trading/deals-list/:param',
    name: 'trading-deals-list',
    meta: {
      pageTitle: i18n.t('deals'),
      requiresAuth: true,
    },
    component: () => import('@/views/trading/trading-deals-list/TradingDeals.vue'),
  },
  {
    path: '/trading/warehouse/',
    name: 'trading-warehouse-list',
    meta: {
      pageTitle: i18n.t('warehouse'),
      requiresAuth: true,
    },
    component: () => import('@/views/trading/trading-warehouse/Warehouse.vue'),
  },
  {
    path: '/trading/deals/:id',
    name: 'trading-deals-add',
    meta: {
      pageTitle({ params: { id } }) {
        return `${i18n.t('deal')} #${id}`;
      },
      requiresAuth: true,
    },
    component: () => import('@/views/trading/trading-deals/TradingDealNew.vue'),
  },
];
